import React from "react"
import { Link } from "gatsby"

export default function Home() {
  return (
    <div>
      <div className="logo">
        <Link to="/">
          <img
            width="100"
            src="https://rammware.blob.core.windows.net/images/M365logo3.PNG"
            alt="M365 Logo"
          />
        </Link>
      </div>
      <h1>This is the future home of the M365 Community site!</h1>
      <h2>Hosted by Azure, Powered by SharePoint</h2>
      <h3>The site will be up as soon as possible.</h3>
    </div>
  )
}
